body, html {
  max-height: 100%;
  min-height: 100vh;
  margin: 0px;
  padding: 0px;
  /* overflow-y: hidden; */
  /* overflow-x: hidden;  */
}

.root {
  margin: 0;
}
.App {
  text-align: center;
  font-family: 'VT323', monospace;
  height: 100;
}

header {
  background: #e74444;
  position: sticky;
  top: 0;
  z-index: 3;
  font-family: 'VT323', monospace;
  
}

nav {
  margin: 0 60px;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

}

#header-overlay {
  position: absolute;
  top: 0;
  height: 550px;
  width: 100%;
}

#text-fader {
  position: sticky;
  background: linear-gradient(#e74444, #e7444400);
  height: 106px;
  margin: 0 310px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 6;
}

a {
  text-decoration: none;
}

#hero-text {
  font-size: 32px;
  font-weight: bold;
  user-select: none;
  color: white;
}

#home {
  position: absolute;
  top: 0;
}

.nav-buttons {
  display: inline-block;
  font-size: 20px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  margin-left: 20px;
}

.nav-buttons {
  cursor: pointer;
}

.nav-buttons:hover {
  text-decoration: underline;
}

.nav-buttons:active {
  color: rgb(190, 190, 190);
}

main {
  position: relative;
  background: #e74444;
}

#hero-section-container {
  padding: 125px 310px 175px 310px;
  font-size: 28px;
  color: white;
  background: #e74444;
  font-family: 'Maven Pro', sans-serif;
  position: relative;
  height: 20vh;
}

#hero-section-container p {
  position: relative;
  z-index: 5;
}

.portfolio-paralax {
  background: linear-gradient(#e74444, #3d3939);
  margin: 0;
}

#project-cards-container {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-around;
  width: 100%;
}

.project-card-containers {
  width: 300px;
  height: 300px;
  margin: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.project-cards {
  width: 270px;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  z-index: 0;
  transition-property: width, height, box-shadow, z-index;
  transition-duration: .1s;
  transition-timing-function: linear;
}


.project-cards:hover {
  width: 300px;
  height: 300px;
  box-shadow: 0px 10px 100px -10px rgba(0, 0, 0, 0.4);
  z-index: 1;
  transition-property: width, height, box-shadow, z-index;
  transition-duration: .1s;
  transition-timing-function: linear;
}

.project-card-overlays {
  width: 270px;
  height: 270px;
  background-color: #e7444400;
  color: #ffffff00;
  transition-property: background-color, width, height;
  transition-duration: .15s;
  transition-timing-function: ease-in;
  user-select: none;
}

.project-card-overlays:hover {
  width: 260px;
  height: 260px;
  color: #ffffff;
  background-color: #e74444a9;
  transition-property: background-color, width, height;
  transition-duration: .15s;
  transition-timing-function: ease-in;
}

.project-card-overlays:active {
  opacity: .5;
}

.project-card-titles {
  font-size: 36px;
  font-weight: bold;
  text-decoration: none;
}

.project-card-descrips {
  font-size: 22px;
  font-weight: bold;
}

.project-card-tools {
  font-size: 18px;
  font-weight: bold;
}

.github-project-link {
  cursor: default;
  font-weight: bold;
}

.github-project-link:visited {
  color: white;
}

#project-card-qwickpoll {
  background-image: url(./assets/projects/qwickpoll.png);
}

#project-card-bennys {
  background-image: url(./assets/projects/bennys.jpg);
}

#project-card-tackle {
  background-image: url(./assets/projects/tackle.jpg);

}

#project-card-beatrun {
  background-image: url(./assets/projects/beatmyrun.jpg);
}

#project-card-match {
  background-image: url(./assets/projects/cardmatch.png);
}

#about-control {
  position: relative;
}

#about {
  position: absolute;
  width: 10px;
  height: 10px;
  top: -120px;
}

#about-div {
  position: relative;
  padding-bottom: 5px;
  background: linear-gradient(#3d3939, #2c2929, #252222, #212020);
}

#about-container-outer-div{
  padding: 0 0 50px 0;
  margin: 0 auto;
}

#about-container {
  width: 600px;
  margin: 0 auto;
  background: linear-gradient(270deg, #cac6c6, #b5b1b1);
  border: 3px solid #898585;
  padding: 15px;
  border-radius: 30px;
}

#about-pic {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  border: 6px solid #928c8c;
  display: inline-block;
  margin-right: 10px;
  user-select: none;
}

#about-text {
  display: inline-block;
  width: 300px;
  font-size: 16px;
  margin: 0 0 0 10px;
  text-align: left;
  font-family: 'Maven Pro', sans-serif;
}

#button-mail {
  display: none;
}

#button-mail:active {
  opacity: .5;
}



.img-skill-containers {
  width: 50px;
  height: 50px;
  margin: 10px;
  position: relative;
  display: inline-block;
}

.img-skills, .img-color-skills {
  width: 50px;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
}

.img-color-skills{
  opacity: 0;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: linear;
}

.img-color-skills:hover{
  opacity: 1;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: linear;
}
.img-color-skills:active{
  opacity: .5;
  transition-property: opacity;
  transition-duration: .01s;
  transition-timing-function: linear;
}

#contact-container {
  background: #212020;
}

form {
  padding: 40px 0 0 0;
}

.form-input {
  font-size: 20px;
  width: 300px;
  margin: 10px;

}

#button-form-submit {
  font-size: 26px;
  font-family: 'VT323', monospace;
  border: none;
  outline: none;
  color: #212020;
  cursor: pointer;
}

#button-form-submit:active {
  color: white;
  background: #212020;
}

footer {
  background: #212020;
  padding: 0 0 70px 0;
  padding-top: 0;
}

@media only screen and (max-width: 1349px) {

  .project-card-containers {
    width: 260px;
    height: 260px;
    margin: -15px;
  }
  
  .project-cards {
    width: 230px;
    height: 230px;
  }
  
  
  .project-cards:hover {
    width: 260px;
    height: 260px;
  }
  
  .project-card-overlays {
    width: 230px;
    height: 230px;
  }
  
  .project-card-overlays:hover {
    width: 220px;
    height: 220px;
  }
  
  .project-card-titles {
    font-size: 32px;
  }
  
  .project-card-descrips {
    font-size: 20px;
  }
  
  .project-card-tools {
    font-size: 16px;
  }
  
}

@media only screen and (max-width: 1149px) {
  #hero-section-container {
    font-size: 20px;
  }

  .project-card-containers {
    width: 300px;
    height: 325px;
    margin: -15px;
  }
  
  .project-cards {
    width: 270px;
    height: 270px;
  }
  
  
  .project-cards:hover {
    width: 300px;
    height: 300px;
  }
  
  .project-card-overlays {
    width: 270px;
    height: 270px;
  }
  
  .project-card-overlays:hover {
    width: 260px;
    height: 260px;
  }
  
  .project-card-titles {
    font-size: 36px;
  }
  
  .project-card-descrips {
    font-size: 22px;
  }
  
  .project-card-tools {
    font-size: 18px;
  }
}

@media only screen and (max-width: 999px) {
  header {
    z-index: 4;
  }

  #header-overlay {
    position: absolute;
    top: 4;
    width: 100%;
  }
  
  #text-fader {
    background: linear-gradient(#e74444, #e7444400);
    top: 106px;
    margin: 0;
    z-index: 2;
  }

  #hero-section-container p {
    position: relative;
    z-index: 1;
  }
  #hero-section-container {
    padding: 125px 0 175px 0;
    width: 400px;
    height: 15vh;
    margin: 0 auto;
  }

  .parallax-outer  {
    position: relative;
    z-index: 3;
  }

}

@media only screen and (max-width: 809px) {
  .project-card-containers {
    width: 260px;
    height: 300px;
    margin: -15px;
  }
  
  .project-cards {
    width: 230px;
    height: 230px;
  }
  
  
  .project-cards:hover {
    width: 260px;
    height: 260px;
  }
  
  .project-card-overlays {
    width: 230px;
    height: 230px;
  }
  
  .project-card-overlays:hover {
    width: 220px;
    height: 220px;
  }
  
  .project-card-titles {
    font-size: 32px;
  }
  
  .project-card-descrips {
    font-size: 20px;
  }
  
  .project-card-tools {
    font-size: 16px;
  }
  
}

@media only screen and (max-width: 689px) {
  .nav-buttons {
    font-size: 14px;
  }

  #hero-text {
    font-size: 18px;
  }

  #text-fader {
    top: 64px;
  }

  .project-card-containers {
    width: 300px;
    height: 325px;
    margin: -15px;
  }
  
  .project-cards {
    width: 270px;
    height: 270px;
  }
  
  
  .project-cards:hover {
    width: 300px;
    height: 300px;
  }
  
  .project-card-overlays {
    width: 270px;
    height: 270px;
  }
  
  .project-card-overlays:hover {
    width: 260px;
    height: 260px;
  }
  
  .project-card-titles {
    font-size: 36px;
  }
  
  .project-card-descrips {
    font-size: 22px;
  }
  
  .project-card-tools {
    font-size: 18px;
  }

  .portfolio-paralax {
    height: 500px;
  }

  #about-div {
    z-index: 10;
  }
}

@media only screen and (max-width: 649px) {
  
  #about-container {
    width: 300px;
  }
  
  #button-mail {
    display: none;
  }

  

}

@media only screen and (max-width: 414px) {
  #text-fader {
    top: 64;
  }

  #hero-section-container {
    width: 300px;
    height: 15vh;
  }

  #header-overlay {
    background-color: #e74444;
  }

  nav {
    margin: 30px
  }

  #contact-container {
    display: none;
  }

  #button-mail {
    display: block;
    width: 50px;
    height: 50px;
    background: url(./assets/profile/mail.png);
    background-size: 50px 50px;
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 0 auto 50px auto;
    user-select: none;
    transition-property: filter;
    transition-duration: .1s;
    transition-timing-function: linear;
  }

  #button-mail:hover {
    filter: drop-shadow(0px 0px 10px #efe6b9be);
    transition-property: filter;
    transition-duration: .1s;
    transition-timing-function: linear;
  }


}

@media only screen and (max-width: 375px)
and (orientation: portrait) {

  #hero-section-container {
    height: 20vh;
  }
}